import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { hashCoordinates } from '../utils/hashCoordinates';
import Land from '../utils/landModel';  // Import the Land model

const GridLayer = ({ rectangle, setRectangle, setStatusData, setShowLogin, setLoading, setUseFlyTo }) => {
  const map = useMap();
  const rectangleRef = useRef(null);
  const gridLayerRef = useRef(null);

  useEffect(() => {
    const createGrid = () => {
      const bounds = map.getBounds();
      const zoom = map.getZoom();

      if (zoom < 15) {
        if (gridLayerRef.current) {
          map.removeLayer(gridLayerRef.current);
          gridLayerRef.current = null;
        }
        return;
      }

      // Clear previous grid lines
      if (gridLayerRef.current) {
        map.removeLayer(gridLayerRef.current);
      }

      // Create a new layer group for the grid lines
      const gridLayer = L.layerGroup();
      const latLines = [];
      const lngLines = [];
      
      for (let lat = Math.floor(bounds.getSouth() / 0.00009) * 0.00009; lat <= Math.ceil(bounds.getNorth()); lat += 0.00009) {
        latLines.push(lat);
      }

      for (let lng = Math.floor(bounds.getWest() / 0.000135) * 0.000135; lng <= Math.ceil(bounds.getEast()); lng += 0.000135) {
        lngLines.push(lng);
      }

      // Draw new grid lines
      latLines.forEach(lat => {
        L.polyline([[lat, bounds.getWest()], [lat, bounds.getEast()]], { color: 'rgba(211, 211, 211, 0.5)', weight: 1, noClip: true }).addTo(gridLayer);
      });

      lngLines.forEach(lng => {
        L.polyline([[bounds.getSouth(), lng], [bounds.getNorth(), lng]], { color: 'rgba(211, 211, 211, 0.5)', weight: 1, noClip: true }).addTo(gridLayer);
      });

      gridLayer.addTo(map);
      gridLayerRef.current = gridLayer;

      // Redraw the rectangle if it exists
      if (rectangle && rectangle.length === 4) {
        if (rectangleRef.current) {
          map.removeLayer(rectangleRef.current);
        }
        rectangleRef.current = L.polygon(rectangle, { color: 'orange', weight: 1 }).addTo(map);
      }
    };

    const handleMapChange = () => {
      createGrid();
    };

    map.on('moveend', handleMapChange);
    map.on('zoomend', handleMapChange);

    createGrid();

    return () => {
      map.off('moveend', handleMapChange);
      map.off('zoomend', handleMapChange);
    };
  }, [map, rectangle]);

  useEffect(() => {
    const highlightClosestGridLines = async (e) => {
      const bounds = map.getBounds();
      const zoom = map.getZoom();

      // if (zoom < 15) {
      //   return;
      // }

      const latLines = [];
      const lngLines = [];

      for (let lat = Math.floor(bounds.getSouth() / 0.00009) * 0.00009; lat <= Math.ceil(bounds.getNorth()); lat += 0.00009) {
        latLines.push(lat);
      }

      for (let lng = Math.floor(bounds.getWest() / 0.000135) * 0.000135; lng <= Math.ceil(bounds.getEast()); lng += 0.000135) {
        lngLines.push(lng);
      }

      const closestLatLineAbove = latLines.find(lat => lat > e.latlng.lat);
      const closestLatLineBelow = [...latLines].reverse().find(lat => lat < e.latlng.lat);
      const closestLngLineLeft = [...lngLines].reverse().find(lng => lng < e.latlng.lng);
      const closestLngLineRight = lngLines.find(lng => lng > e.latlng.lng);

      const intersectionPoints = [
        [closestLatLineAbove, closestLngLineLeft],
        [closestLatLineAbove, closestLngLineRight],
        [closestLatLineBelow, closestLngLineRight],
        [closestLatLineBelow, closestLngLineLeft]
      ];

      if (intersectionPoints.every(point => point[0] !== undefined && point[1] !== undefined)) {
        setRectangle(intersectionPoints);
        const centerCoordinates = getCenterCoordinate(intersectionPoints);
        const landId = hashCoordinates([centerCoordinates.lat.toFixed(6), centerCoordinates.lng.toFixed(6)]);


        setLoading(true);
        const existingLand = await Land.getLandById(landId);

        let land;
        console.log(land);
        if (existingLand) {
          land = existingLand;
          console.log('clicked:', land.owner_id); // Log the object directly
          console.log('clicked (stringified): ' + JSON.stringify(existingLand, null, 2)); // Log the object as a string
        } else {
          console.log("Land is free to purchase");
          land = new Land(intersectionPoints, centerCoordinates, landId);
        }
        
        setStatusData({
          landId: landId,
          intersectionPoints: intersectionPoints.map(point => [point[0], point[1]]),
          centerCoordinates: [centerCoordinates.lat, centerCoordinates.lng],
          owner_id: land.owner_id,
          price: land.price,
          unit: land.unit
        });
        setLoading(false);
        setUseFlyTo(false);  // Disable flyTo for map clicks
      }
    };

    map.on('click', highlightClosestGridLines);

    return () => {
      map.off('click', highlightClosestGridLines);
    };
  }, [map, setRectangle, setStatusData, setLoading, setUseFlyTo]);

  const getCenterCoordinate = (rectangle) => {
    const lats = rectangle.map(point => point[0]);
    const lngs = rectangle.map(point => point[1]);
    const centerLat = (Math.min(...lats) + Math.max(...lats)) / 2;
    const centerLng = (Math.min(...lngs) + Math.max(...lngs)) / 2;
    return { lat: centerLat, lng: centerLng };
  };

  useEffect(() => {
    const fetchOccupiedLands = async () => {
      const bounds = map.getBounds();
      try {
        const occupiedLands = await Land.getLandsByCoordinateRange(
          bounds.getSouth(),
          bounds.getNorth(),
          bounds.getWest(),
          bounds.getEast()
        );
        // Highlight the occupied lands
        occupiedLands.forEach(land => {
          const color = `#${hashCoordinates([land.owner]).slice(0, 6)}`;
          const polygon = L.polygon(land.intersectionPoints.map(point => [point.lat, point.lng]), { color }).addTo(map);
          polygon.on('click', () => {
            setStatusData({
              landId: hashCoordinates([land.centerCoordinates.lat, land.centerCoordinates.lng]),
              intersectionPoints: land.intersectionPoints.map(point => [point.lat, point.lng]),
              centerCoordinates: [land.centerCoordinates.lat, land.centerCoordinates.lng],
              owner: land.owner,
              price: land.price,
              unit: land.unit
            });
          });
        });
      } catch (error) {
        console.error("Error fetching occupied lands:", error);
      }
    };

    map.on('moveend', fetchOccupiedLands);
    fetchOccupiedLands();

    return () => {
      map.off('moveend', fetchOccupiedLands);
    };
  }, [map, setStatusData]);

  return null;
};

export default GridLayer;
