import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Navbar, Nav, Button, Container, Modal, Form, Alert, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import './App.css';
import GridLayer from './components/GridLayer';
import StatusBox from './components/StatusBox';
import LoginModal from './components/LoginModel';
import MapController from './components/MapController';
import LandListModel from './components/LandListModel';
import { auth } from './firebase-config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import ErrorBoundary from './ErrorBoundary';
import { FaApple, FaAndroid } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import SearchBar from './components/SearchBar'; 

function App() {
  const { t } = useTranslation();
  const [rectangle, setRectangle] = useState([]);
  const [statusData, setStatusData] = useState(null);
  const [user, setUser] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState([22.3193, 114.1694]);
  const [useFlyTo, setUseFlyTo] = useState(false);
  const [showLandList, setShowLandList] = useState(false);
  const [ownedLands, setOwnedLands] = useState([]);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState('');
  const [redeemError, setRedeemError] = useState('');
  const [unclaimedCount, setUnclaimedCount] = useState(0);
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, newUser => {
      setUser(newUser);
      if (newUser) {
        fetchUnclaimedLands(newUser.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log("Logged out successfully!");
    }).catch((error) => {
      console.error("Logout error:", error);
    });
  };

  const handleLandClick = (land) => {
    setStatusData(land);
    setRectangle(land.intersectionPoints.map(point => ({ lat: point.lat, lng: point.lng })));
    setCenter([land.centerCoordinates[0], land.centerCoordinates[1]]);
    setUseFlyTo(true);
  };

  const fetchOwnedLands = async (firebaseUid) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/get_lands_by_owner.php`, { firebaseUid });
      const lands = response.data;
      setOwnedLands(lands);
    } catch (error) {
      console.error("Error fetching owned lands:", error);
    }
    setLoading(false);
  };

  const fetchUnclaimedLands = async (userId) => {
    try {
      const response = await axios.post(`${API_URL}/count_unclaimed_lands.php`, { userId });
      const { unclaimed_count } = response.data;
      setUnclaimedCount(unclaimed_count);
    } catch (error) {
      console.error("Error fetching unclaimed lands count:", error);
    }
  };

  const handleOwnerClick = () => {
    if (user) {
      fetchOwnedLands(user.uid);
      setShowLandList(true);
    }
  };

  const handleRedeem = async (e) => {
    e.preventDefault();
    setRedeemError('');
    setRedeemLoading(true);
    try {
      const response = await axios.post(`${API_URL}/redeem_land.php`, { userId: user.uid, redeemCode });
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      alert(t('redeemSuccess'));
      setShowRedeemModal(false);
      fetchUnclaimedLands(user.uid); // Update unclaimed lands count
    } catch (error) {
      console.error('Redeem error:', error);
      setRedeemError(t('redeemFailed'));
    }
    setRedeemLoading(false);
  };

  const handleClaimRandomLand = async () => {
    if (!user) return;
    setClaimLoading(true);
    try {
      const response = await axios.post(`${API_URL}/claim_random_land.php`, {
        firebaseUid: user.uid,
        email: user.email
      });
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      alert(t('claimSuccess'));
      fetchOwnedLands(user.uid); // Refresh owned lands
      fetchUnclaimedLands(user.uid); // Update unclaimed lands count
    } catch (error) {
      console.error('Claim random land error:', error);
      alert(t('claimFailed'));
    }
    setClaimLoading(false);
  };

  return (
    <ErrorBoundary>
      <div className="App">
        <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="#home">Apptaverse</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
              </Nav>
              <Nav className="ml-auto">
                {user ? (
                  <>
                    <Navbar.Text className="me-3">
                      {t('loggedInAs')}: {user.email}
                    </Navbar.Text>
                    <Button variant="outline-light" className="me-2" onClick={handleOwnerClick}>{t('myLands')}</Button>
                    <Button 
                      variant="outline-light" 
                      className="me-2" 
                      onClick={handleClaimRandomLand}
                      disabled={claimLoading || unclaimedCount === 0}
                    >
                      {claimLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : `${t('unclaimedLands')}: ${unclaimedCount}`}
                    </Button>
                    <Button variant="outline-light" className="me-2" onClick={() => setShowRedeemModal(true)}>{t('redeemLand')}</Button>
                    <Button variant="outline-light" className="me-2" onClick={handleLogout}>{t('logout')}</Button>
                  </>
                ) : (
                  <Button variant="outline-light" className="me-2" onClick={() => setShowLogin(true)}>{t('login')}</Button>
                )}
                  <Button variant="outline-light" className="me-2" href="https://apps.apple.com/us/app/maze-catcher/id6499084131">
                    <FaApple /> {t('downloadForiOS')}
                  </Button>
                  <Button variant="outline-light" className="me-2" href="./android.apk">
                    <FaAndroid /> {t('downloadForAndroid')}
                  </Button>
                  <Button variant="outline-light" className="me-1" onClick={() => i18next.changeLanguage('en')}>EN</Button>
                  <Button variant="outline-light" className="me-1" onClick={() => i18next.changeLanguage('zh')}>中</Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <main>
          <MapContainer center={center} zoom={13} className="map-container">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <GridLayer 
              rectangle={rectangle} 
              setRectangle={setRectangle} 
              setStatusData={setStatusData} 
              setShowLogin={setShowLogin} 
              setLoading={setLoading} 
              setUseFlyTo={setUseFlyTo} // Pass setUseFlyTo to GridLayer
            />
            {center && rectangle.length > 0 && <MapController center={center} rectangle={rectangle} useFlyTo={useFlyTo} />}
            <SearchBar setCenter={(coords) => { console.log(coords);setCenter(coords); setUseFlyTo(true); }} />  
          </MapContainer>
          {statusData && (
            <StatusBox 
              statusData={statusData} 
              loggedIn={user != null} 
              setShowLogin={setShowLogin} 
              onLandClick={handleLandClick} 
              setLoading={setLoading} 
              setStatusData={setStatusData} // Ensure setStatusData is passed correctly
            />
          )}
          {loading && <div className="loading">{t('loading')}</div>}
        </main>
        <LoginModal show={showLogin} handleClose={() => setShowLogin(false)} />
        
        {/* Modal for Land List */}
        <Modal show={showLandList} onHide={() => setShowLandList(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('myLands')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LandListModel lands={ownedLands} onLandClick={handleLandClick} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowLandList(false)}>{t('close')}</Button>
          </Modal.Footer>
        </Modal>

        {/* Modal for Redeem Land */}
        <Modal show={showRedeemModal} onHide={() => setShowRedeemModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('redeemLand')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleRedeem}>
              <Form.Group controlId="formRedeemCode">
                <Form.Label>{t('redeemCode')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('enterRedeemCode')}
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  disabled={redeemLoading}
                />
              </Form.Group>
              {redeemError && <Alert variant="danger" className="mt-3">{redeemError}</Alert>}
              <Button variant="primary" type="submit" className="mt-3" disabled={redeemLoading}>
                {redeemLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('redeem')}
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowRedeemModal(false)} disabled={redeemLoading}>{t('close')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </ErrorBoundary>
  );
}

export default App;
