
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDFcw9gzaSTbnnTv4KklqU_yx91tGPKtSg",
    authDomain: "apptaverse-9e573.firebaseapp.com",
    projectId: "apptaverse-9e573",
    storageBucket: "apptaverse-9e573.appspot.com",
    messagingSenderId: "2164346067",
    appId: "1:2164346067:web:3db6ca0b10533ec0b81826"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);

// Initialize Firebase Auth
const auth = getAuth(app);

export { firestore, auth, app };