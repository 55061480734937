// components/SearchBar.js
import React, { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import axios from 'axios';

const SearchBar = ({ setCenter }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searching, setSearching] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery) return;

    setSearching(true);
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${searchQuery}&format=json`);
      if (response.data.length > 0) {
        const { lat, lon } = response.data[0];
        setCenter([parseFloat(lat), parseFloat(lon)]);
      } else {
        alert('Location not found');
      }
    } catch (error) {
      console.error('Error searching location:', error);
    }
    setSearching(false);
  };

  return (
    <Form onSubmit={handleSearch} className="search-bar">
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Search location"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          disabled={searching}
        />
        <Button type="submit" disabled={searching}>
          {searching ? 'Searching...' : 'Search'}
        </Button>
      </InputGroup>
    </Form>
  );
};

export default SearchBar;
