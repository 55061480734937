import { useMap } from 'react-leaflet';
import { useEffect } from 'react';
import L from 'leaflet';  // Import Leaflet

const MapController = ({ center, rectangle, useFlyTo }) => {
  const map = useMap();
  useEffect(() => {
    if (center) {
      if (useFlyTo) {
        map.flyTo(center, 18);
      } else {
      }
    }
    if (rectangle && rectangle.length > 0) {
      const layerGroup = L.layerGroup();
      const bounds = rectangle.map(point => {
        if (point && point.lat !== undefined && point.lng !== undefined) {
          return [point.lat, point.lng];
        }
        return null;
      }).filter(point => point !== null);

      if (bounds.length > 0) {
        L.polygon(bounds, { color: 'orange', weight: 1 }).addTo(layerGroup);
        layerGroup.addTo(map);

        // Clean up the layer group when the component unmounts or updates
        return () => {
          map.removeLayer(layerGroup);
        };
      }
    }
  }, [center, rectangle, map, useFlyTo]);

  return null;
};

export default MapController;
