import React, { useState } from 'react';
import { Modal, Form, Button, Tab, Nav, Alert } from 'react-bootstrap';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase-config';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const LoginModal = ({ show, handleClose }) => {
  const { t } = useTranslation(); // Use the useTranslation hook
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [activeKey, setActiveKey] = useState('login');
  const [error, setError] = useState('');
  const [resetError, setResetError] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Login successful!");
      handleClose(); // Close modal on successful login
    } catch (error) {
      console.error('Login error:', error);
      handleAuthError(error); // Handle errors more gracefully
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      // After successful registration, call the PHP backend to create the user in your database
      const response = await axios.post(`${API_URL}/create_user.php`, {
        username: email,
        password: password,
        firebase_uid: userCredential.user.uid,
      });
      console.log(response);
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      console.log("Registration and backend user creation successful!");
      handleClose(); // Close modal on successful registration
    } catch (error) {
      console.error('Registration error:', error);
      handleAuthError(error); // Handle registration errors
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setResetError('');
    setResetMessage('');
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage(t('passwordResetEmailSent'));
    } catch (error) {
      console.error('Password reset error:', error);
      switch (error.code) {
        case 'auth/invalid-email':
          setResetError(t('invalidEmail'));
          break;
        case 'auth/user-not-found':
          setResetError(t('userNotFound'));
          break;
        default:
          setResetError(t('unexpectedError'));
          break;
      }
    }
  };

  const handleAuthError = (error) => {
    // Customize error messages based on error.code
    switch (error.code) {
      case 'auth/invalid-email':
        setError(t('invalidEmail'));
        break;
      case 'auth/user-disabled':
        setError(t('userDisabled'));
        break;
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        setError(t('incorrectUsernameOrPassword'));
        break;
      case 'auth/weak-password':
        setError(t('weakPassword'));
        break;
      case 'auth/email-already-in-use':
        setError(t('emailAlreadyInUse'));
        break;
      case 'auth/invalid-credential':
        setError(t('invalidCredential'));
        break;
      default:
        setError(t('unexpectedError'));
        break;
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t(activeKey === 'login' ? 'login' : activeKey === 'register' ? 'register' : 'resetPassword')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link eventKey="login">{t('login')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="register">{t('register')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="reset">{t('resetPassword')}</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            {error && <Alert variant="danger">{error}</Alert>}
            {resetError && <Alert variant="danger">{resetError}</Alert>}
            {resetMessage && <Alert variant="success">{resetMessage}</Alert>}
            <Tab.Pane eventKey="login">
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="formLoginEmail">
                  <Form.Label>{t('email')}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t('enterEmail')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formLoginPassword">
                  <Form.Label>{t('password')}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t('enterPassword')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <br />
                <Button variant="primary" type="submit">
                  {t('login')}
                </Button>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="register">
              <Form onSubmit={handleRegister}>
                <Form.Group controlId="formRegisterEmail">
                  <Form.Label>{t('email')}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t('enterEmail')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <br />
                <Form.Group controlId="formRegisterPassword">
                  <Form.Label>{t('password')}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t('enterPassword')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <br />
                <Button variant="primary" type="submit">
                  {t('register')}
                </Button>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="reset">
              <Form onSubmit={handlePasswordReset}>
                <Form.Group controlId="formResetEmail">
                  <Form.Label>{t('email')}</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t('enterEmail')}
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                  />
                </Form.Group>
                <br />
                <Button variant="primary" type="submit">
                  {t('resetPassword')}
                </Button>
              </Form>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
