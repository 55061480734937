import React from 'react';
import { ListGroup, Button } from 'react-bootstrap';

const LandListModel = ({ lands = [], onLandClick }) => {

  return (
    <div>
      <ListGroup>
        {lands.map(land => (
          <ListGroup.Item key={land.id} style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ flex: 1, overflowWrap: 'anywhere' }}>
              {land.id}
            </span>
            <Button
              variant="outline-primary"
              size="sm"
              style={{ marginLeft: '10px' }}
              onClick={() => onLandClick(land)}
            >
              View
            </Button>
            <span role="img" aria-label="land" style={{ marginLeft: '10px' }}>
              🌍
            </span>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default LandListModel;
