import React, { useState } from 'react';
import { Card, Button, Modal, Form, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LandListModel from './LandListModel';
const API_URL = process.env.REACT_APP_API_URL;
const StatusBox = ({ statusData, loggedIn, setShowLogin, onLandClick, setLoading, setStatusData }) => {
  const { t } = useTranslation(); // Use the useTranslation hook

  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [purchaseError, setPurchaseError] = useState('');
  const [showOwnerLandsModal, setShowOwnerLandsModal] = useState(false);
  const [ownerLands, setOwnerLands] = useState([]);
  const [loadingOwnerLands, setLoadingOwnerLands] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const handlePurchase = async (e) => {
    e.preventDefault();
    setPurchaseError('');
    if (!loggedIn) {
      setShowLogin(true);
    } else {
      try {
        setLoading(true);
        // Simulate successful submission
        setLoading(false);
        setConfirmationMessage(t('transactionConfirmed'));
      } catch (error) {
        console.error("Error purchasing land: ", error);
        setPurchaseError(t('purchaseFailed'));
        setLoading(false);
      }
    }
  };

  const resetPurchaseState = () => {
    setTransactionId('');
    setConfirmationMessage('');
    setShowPaymentMethodModal(false);
  };

  const renderCoordinates = (coordinates) => {
    return coordinates.map((point, index) => {
      const lat = typeof point.lat !== 'undefined' ? point.lat : point[0];
      const lng = typeof point.lng !== 'undefined' ? point.lng : point[1];
      return (
        <div key={index}>
          {index + 1}: ({lat.toFixed(5)}, {lng.toFixed(5)})
        </div>
      );
    });
  };

  const fetchOwnerLands = async (ownerId) => {
    setLoadingOwnerLands(true);
    try {
      const response = await axios.post(`${API_URL}/get_lands_by_owner.php`, { firebaseUid: ownerId });
      const lands = response.data;
      setOwnerLands(lands);
      setShowOwnerLandsModal(true);
    } catch (error) {
      console.error("Error fetching owner's lands:", error);
    }
    setLoadingOwnerLands(false);
  };

  const centerCoordinates = statusData.centerCoordinates || [0, 0];
  const intersectionPoints = statusData.intersectionPoints || [];

  return (
    <div className="status-box">
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title>{t('landInformation')}</Card.Title>
            <Button variant="outline-secondary" size="sm" onClick={() => setStatusData(null)}>{t('close')}</Button>
          </div>
          <Card.Text as="div">
            <strong>{t('landId')}:</strong> {statusData.landId || statusData.id}<br />
            <strong>{t('cornerCoordinates')}:</strong><br />
            {intersectionPoints.length > 0 ? renderCoordinates(intersectionPoints) : 'N/A'}<br />
            <strong>{t('centerCoordinates')}:</strong> {centerCoordinates[0].toFixed(5)}, {centerCoordinates[1].toFixed(5)}<br />
            <strong>{t('owner')}:</strong> 
            {statusData.owner_id ? (
              <Button variant="link" onClick={() => fetchOwnerLands(statusData.owner_id)}>
                {statusData.owner_id}
              </Button>
            ) : 'None'}<br />
            {!statusData.owner_id && (
              <>
                <strong>{t('price')}:</strong> {statusData.price || 0} {statusData.unit || 'WM'}<br />
                <Button variant="primary" onClick={() => setShowPaymentMethodModal(true)}>{t('purchase')}</Button>
              </>
            )}
          </Card.Text>
        </Card.Body>
      </Card>

      <Modal show={showPaymentMethodModal} onHide={resetPurchaseState} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirmPurchase')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmationMessage ? (
            <Alert variant="success">{confirmationMessage}</Alert>
          ) : (
            <Form onSubmit={handlePurchase}>
              <Form.Group controlId="formTransactionId">
                <Form.Label>{t('transactionId')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('transactionId')}
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </Form.Group>
              {purchaseError && <Alert variant="danger" className="mt-3">{purchaseError}</Alert>}
              <Button variant="primary" type="submit" className="mt-3">{t('confirmPurchase')}</Button>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetPurchaseState}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showOwnerLandsModal} onHide={() => setShowOwnerLandsModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('ownersLands')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOwnerLands ? (
            <Spinner animation="border" />
          ) : (
            <LandListModel lands={ownerLands} onLandClick={onLandClick} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOwnerLandsModal(false)}>{t('close')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StatusBox;
