import axios from 'axios';
import { auth } from '../firebase-config'; // Assuming you have firebase-config for authentication

const API_URL = process.env.REACT_APP_API_URL;

class Land {
  constructor(intersectionPoints, centerCoordinates, id, owner = null, price = 1, unit = 'WM') {
    this.intersectionPoints = intersectionPoints.map(point => ({ lat: point[0], lng: point[1] }));
    this.centerCoordinates = { lat: centerCoordinates[0], lng: centerCoordinates[1] };
    this.id = id;
    this.owner = owner;
    this.price = price;
    this.unit = unit;
  }

  static fromData(data) {
    if (!data) {
      return null;
    }
    return new Land(
      data.intersectionPoints ? data.intersectionPoints.map(point => [point.lat, point.lng]) : [],
      data.centerCoordinates ? [data.centerCoordinates.lat, data.centerCoordinates.lng] : [0, 0],
      data.id || '',
      data.owner || null,
      data.price || 1,
      data.unit || 'WM'
    );
  }

  static async getLandById(landId) {
    try {
      const response = await axios.post(`${API_URL}/get_land.php`, { landId }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data) {
        return response.data[0];
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching land:', error);
      return null;
    }
  }

  static async getLandsByCoordinateRange(south, north, west, east) {
    try {
      console.log("Sending parameters:", { south, north, west, east });
      const response = await axios.post(`${API_URL}/get_lands_by_coordinates.php`, { south, north, west, east }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log("Server response:", response.data); // Log server response
      if (Array.isArray(response.data)) {
        return response.data.map(landData => Land.fromData(landData));
      } else {
        console.error("Unexpected response format:", response.data);
        return [];
      }
    } catch (error) {
      console.error('Error fetching lands:', error);
      return [];
    }
  }

  static async getLandsByOwner(ownerId) {
    try {
      const response = await axios.post(`${API_URL}/get_lands.php`, { owner: ownerId }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (Array.isArray(response.data)) {
        return response.data.map(landData => Land.fromData(landData));
      } else {
        console.error("Unexpected response format:", response.data);
        return [];
      }
    } catch (error) {
      console.error('Error fetching lands:', error);
      return [];
    }
  }

  async save() {
    try {
      const response = await axios.post(`${API_URL}/save_land.php`, {
        intersectionPoints: this.intersectionPoints,
        centerCoordinates: this.centerCoordinates,
        landId: this.landId,
        owner: this.owner,
        price: this.price,
        unit: this.unit
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error saving land:', error);
    }
  }
}

export default Land;
