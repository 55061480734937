import CryptoJS from 'crypto-js';

// Function to generate SHA-256 hash from coordinates
const hashCoordinates = ([lat, lng]) => {
  if (!Array.isArray([lat, lng]) || [lat, lng].length !== 2) {
    throw new Error('Invalid input for hashCoordinates. Expected an array with two elements [lat, lng].');
  }
  const str = `${lat},${lng}`;
  return CryptoJS.SHA256(str).toString(CryptoJS.enc.Hex);
};

export { hashCoordinates };
